import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_TableConfig = _resolveComponent("TableConfig")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_TableBody = _resolveComponent("TableBody")!
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TableBody, null, {
      tableConfig: _withCtx(() => [
        _createVNode(_component_TableConfig, {
          border: $setup.tableConfig.border,
          "onUpdate:border": _cache[0] || (_cache[0] = ($event: any) => (($setup.tableConfig.border) = $event)),
          stripe: $setup.tableConfig.stripe,
          "onUpdate:stripe": _cache[1] || (_cache[1] = ($event: any) => (($setup.tableConfig.stripe) = $event)),
          onRefresh: $setup.doRefresh
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              icon: "PlusIcon",
              onClick: $setup.addList
            }, {
              default: _withCtx(() => [
                _createTextVNode("添加部门 ")
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["border", "stripe"])
      ]),
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_el_table, {
          ref: "tableRef",
          data: $setup.dataList,
          "row-key": "id",
          "header-cell-style": $setup.tableConfig.headerCellStyle,
          size: $setup.tableConfig.size,
          stripe: $setup.tableConfig.stripe,
          border: $setup.tableConfig.border,
          height: $setup.tableConfig.height + 0
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "部门层级",
              prop: "name"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "备注",
              prop: "comment"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "操作"
            }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_button, {
                  size: "small",
                  plain: "",
                  type: "primary",
                  onClick: ($event: any) => ($setup.onUpdateItem(scope.row))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("编辑")
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                _createVNode(_component_el_button, {
                  type: "danger",
                  size: "small",
                  plain: "",
                  onClick: ($event: any) => ($setup.onDeleteItem(scope.row))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("删除")
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["data", "header-cell-style", "size", "stripe", "border", "height"])), [
          [_directive_loading, $setup.tableLoading]
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.dialogVisible,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.dialogVisible) = $event)),
      title: $setup.dialogTitle,
      "show-footer": false
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.onSubmitForm())),
            loading: $setup.loading
          }, {
            default: _withCtx(() => [
              _createTextVNode("确定")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["loading"]),
          _createVNode(_component_el_button, {
            onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.onResetForm()))
          }, {
            default: _withCtx(() => [
              _createTextVNode("取消")
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          model: $setup.ruleForm,
          rules: $setup.rules,
          ref: "ruleFormRef",
          "label-width": "100px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: "上级部门" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_cascader, {
                  modelValue: $setup.parent_id1,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.parent_id1) = $event)),
                  options: $setup.dataList,
                  clearable: "",
                  props: { value: 'id', label: 'name', checkStrictly: true }
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_form_item, {
              label: "部门名称",
              prop: "name"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $setup.ruleForm.name,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.ruleForm.name) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_form_item, { label: "备注" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: $setup.ruleForm.comment,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.ruleForm.comment) = $event)),
                  type: "textarea"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "title"])
  ]))
}